import React, { useCallback, useMemo } from 'react'
import { DateTime } from 'luxon'
import { DateRange } from '@mui/x-date-pickers-pro'
import {
  Box,
  Button,
  Text,
  DateRangeCalendar,
} from 'components'
import {
  FiltersPopover,
  FiltersPopoverHeader,
  FiltersPopoverContent,
  FiltersPopoverFooter,
} from 'components/Filters'
import { Calendar as CalendarIcon } from 'icons'
import { usePopoverState, useViewport } from 'hooks'
import { useContext } from '@market/hooks/useFilteredAccountOrders'

export const DateRangeFilter: React.FC = () => {
  const { isMedium } = useViewport()
  const { filters: { filters, appliedFilters, setFilters, applyFilters } } = useContext()
  const dateRange: DateRange<DateTime> = useMemo(() => [filters.startDate, filters.endDate], [filters.startDate, filters.endDate])
  const { open, anchorElement, handleClick, handleClose } = usePopoverState()

  const formattedAppliedStartDate = useMemo(() => {
    return appliedFilters.startDate.toFormat('LLL d')
  }, [appliedFilters.startDate])

  const formattedAppliedEndDate = useMemo(() => {
    if (appliedFilters.startDate.month === appliedFilters.endDate.month) {
      return appliedFilters.endDate.toFormat('d')
    } else {
      return appliedFilters.endDate.toFormat('LLL d')
    }
  }, [appliedFilters.endDate, appliedFilters.startDate])

  const onDateRangeChange = useCallback((newDates: DateRange<DateTime>) => {
    setFilters({
      startDate: (newDates[0]?.setZone(filters.timeZone) || filters.startDate),
      endDate: (newDates[1]?.setZone(filters.timeZone).endOf('day') || filters.endDate),
      filteredDate: undefined,
    })
  }, [filters.startDate, filters.endDate, filters.timeZone, setFilters])

  const applyAndClose = useCallback(() => {
    applyFilters()
    handleClose()
  }, [applyFilters, handleClose])

  const cancelAndClose = useCallback(() => {
    setFilters({ startDate: appliedFilters.startDate, endDate: appliedFilters.endDate })
    handleClose()
  }, [appliedFilters.startDate, appliedFilters.endDate, setFilters, handleClose])

  return <Box>
    <Button onClick={handleClick} variant="outlined" fullWidth={false}>
      <CalendarIcon />
      <Text ml={1}>{ formattedAppliedStartDate } - { formattedAppliedEndDate }</Text>
    </Button>
    <FiltersPopover
      open={open}
      anchorEl={anchorElement}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{ marginTop: { md: 1 } }}
    >
      <FiltersPopoverHeader onClose={handleClose}>
        <Text variant="h6">Date Range</Text>
      </FiltersPopoverHeader>

      <FiltersPopoverContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <DateRangeCalendar
              value={dateRange}
              onChange={onDateRangeChange}
              calendars={isMedium ? 2 : 1}
            />
          </Box>
        </Box>
      </FiltersPopoverContent>

      <FiltersPopoverFooter sx={(theme) => ({
        display: 'flex',
        gap: 1,
        [theme.breakpoints.up('xs')]: {
          flexDirection: 'column',
        },
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
        },
      })}>
        <Box width={isMedium ? undefined : '100%'}>
          <Button fullWidth={!isMedium} onClick={applyAndClose}>Apply</Button>
        </Box>
        <Box width={isMedium ? undefined : '100%'}>
          <Button fullWidth={!isMedium} variant="outlined" onClick={cancelAndClose}>Cancel</Button>
        </Box>
      </FiltersPopoverFooter>
    </FiltersPopover>
  </Box>
}

export default DateRangeFilter
