import React, { useCallback, useMemo, useState } from 'react'
import { styled } from '@mui/material'
import { Button, Box, Text, Currency } from 'components'
import { useResolveFailedPayments } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { PaymentMethodSelector, OrderSummary } from '@market/components'
import { DateTime } from 'luxon'

const OrderSummaries = styled(Box)(({ theme }) => ({
  '& .OrdoOrderSummary-cards': {
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(2),
    },
  },

  '& .OrdoProductQuantityCard-root': {
    [theme.breakpoints.up('md')]: {
      width: '45%',
      maxWidth: '45%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '45%',
      maxWidth: '45%',
    },
  }
}))

export const DelinquentForm: React.FC<{ openModal: () => void }> = ({ openModal }) => {
  const { paymentMethods, account: { id: accountId, delinquentBatches: delinquentAccountBatches } } = useAccountContext()
  const delinquentBatches = useMemo(() => delinquentAccountBatches?.filter((batch) => batch.status === 'failed' && batch.outstandingCents > 0) || [], [delinquentAccountBatches])
  const [ paymentMethodId, setPaymentMethodId ] = useState(paymentMethods.find((pm) => !!pm.default )?.stripeId || null)

  const [ resolveFailedPayments, { loading }] = useResolveFailedPayments()
  const handleResolvePayments = useCallback(() => {
    resolveFailedPayments({ variables: { accountId, batchIds: delinquentBatches.map(batch => batch.id), paymentMethodId } })
  }, [resolveFailedPayments, accountId, delinquentBatches, paymentMethodId])

  const orders = useMemo(() => delinquentBatches?.flatMap((batch) => batch.orders).filter((order) => order.outstandingCents > 0), [delinquentBatches])
  const groupedOrders = orders.sort((a, b) => DateTime.fromISO(a.deliveryDate).toMillis() - DateTime.fromISO(b.deliveryDate).toMillis()).reduce((memo, order) => {
    memo[order.deliveryDate] ||= []
    memo[order.deliveryDate].push(order)
    return memo
  }, {})

  return <>
    <Box sx={(theme) => ({
      display: 'flex',
      alignItems: 'center',
      my: 2,
      py: 2,
      px: 2,
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    })}>
      <Text variant="h5" fontWeight="medium">Past-due amount:</Text>
      <Text ml="auto" variant="h6" fontWeight="normal"><Currency value={delinquentBatches?.reduce((memo, batch) => memo += batch.outstandingCents, 0) / 100} /></Text>
    </Box>

    <Text mb={2}>Please resolve the outstanding balance by either retrying with an existing payment method or adding a new one.</Text>

    { paymentMethods.length > 0 && <PaymentMethodSelector
      paymentMethods={paymentMethods}
      value={paymentMethodId}
      disabled={loading}
      onChange={(evt) => setPaymentMethodId(evt.target.value)}
    /> }

    <Box mt={2}>
      <Button onClick={openModal} fullWidth={false} disabled={loading} color="secondary">Add payment method</Button>
    </Box>

    <Box mt={2}>
      <Button disabled={loading || !paymentMethodId} loading={loading} onClick={handleResolvePayments}>Pay now</Button>
    </Box>

    <Box sx={(theme) => ({
      my: 2,
      pt: 2,
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderStyle: 'solid',
      borderColor: theme.palette.divider,
    })}>
      <Text variant="h5" fontWeight="medium" mb={2}>Past-due orders:</Text>
      <OrderSummaries>
        { Object.keys(groupedOrders).flatMap((groupDate) =>
          groupedOrders[groupDate].map((order) => <OrderSummary key={order.id} order={order} />)
        ) }
      </OrderSummaries>
    </Box>
  </>
}

export default DelinquentForm
