import { gql } from '@market/graphql/schema/gql'

export const availableMenusQuery = gql(`
  query availableMenusQuery {
    availableMenus {
      id
      name
      description
      availabilityDates
      fullAvailabilityDates
      blockedDates
      startDate
      endDate
      menuType
      type
      mealServiceDays
      mealServiceDaysOptions
      mealServiceTimes
      mealServiceTimesOptions
      location {
        id
        name
        displayName
      }
    }
  }
`)

export default availableMenusQuery
