import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Page, Button, Text } from 'components'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { DelinquentForm } from '@market/components'
import { useStripeAddPaymentModal } from '@market/hooks/useStripeAddPaymentModal'
import { AddPaymentMethodModal } from '@market/compositions/StripePayments'

export const Delinquent: React.FC = () => {
  const { account: { id: accountId, delinquentFor }, setupIntent } = useAccountContext()
  const modal = useStripeAddPaymentModal(setupIntent?.clientSecret)
  const { openModal } = modal

  if (!!accountId && (!delinquentFor || delinquentFor === 0)) {
    return <Page>
      <Text variant="h4" mb={2}>Your account is up-to-date</Text>

      <Text mb={2}>You do not have any missed payments at this time. Thank you for keeping your Ordo account current!</Text>

      <Button component={RouterLink} to={'/menu'}>Go to menu</Button>
    </Page>
  } else if (!!accountId && delinquentFor > 0) {
    return <Page>
      <Text variant="h4" mb={2}>Account suspended</Text>

      <Text>Your account has been suspended due to a payment issue. Immediate action is required to reinstate your account.</Text>

      <AddPaymentMethodModal {...modal} />
      <DelinquentForm openModal={openModal} />
    </Page>
  } else {
    return null
  }
}

export default Delinquent
