import React from 'react'
import { Layout as ApplicationLayout } from 'compositions/Application'
import { useCurrentAccount, ContextProvider as AccountContextProvider } from '@market/hooks/useCurrentAccount'
import Routes from '@market/routes'
import NavBar from '@market/compositions/NavBar'
import NavDrawer from '@market/compositions/NavDrawer'
import { HelpDialog } from '../HelpDialog'
import { useHelpDialog } from 'hooks/useHelpDialog'

export const Layout: React.FC<React.PropsWithChildren> = () => {
  const currentAccount = useCurrentAccount()
  const helpDialog = useHelpDialog()

  return <AccountContextProvider {...currentAccount}>
    <ApplicationLayout navBar={NavBar} navDrawer={NavDrawer}>
      <HelpDialog {...helpDialog} />
      <Routes />
    </ApplicationLayout>
  </AccountContextProvider>
}

export default Layout
