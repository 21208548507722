import { gql } from '@market/graphql/schema/gql'

export const availableMenuProductsQuery = gql(`
  query availableMenuProductsQuery($locationId: [ID!]!, $startDate: ISO8601Date, $endDate: ISO8601Date) {
    availableMenuProducts(locationId: $locationId, startDate: $startDate, endDate: $endDate) {
      # availabilityDates
      # fullAvailabilityDates
      menuProducts {
        id
        availabilityDates
        fullAvailabilityDates
        visible
        section {
          id
          name
        }
        product {
          ...SchoolProductFields
        }
        variant {
          id
          # product {
          #   ...SchoolProductFields
          # }
        }
        menu {
          id
          description
          availabilityDates
          fullAvailabilityDates
          menuType
          type
          location {
            id
            name
            displayName
          }
        }
      }
    }
  }
`)

export default availableMenuProductsQuery
