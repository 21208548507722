import { useMemo } from 'react'
import { QueryHookOptions } from '@apollo/client'
import type { AvailableMenu, SchoolLocation } from '@market/graphql/schema/graphql'
import { availableMenusQuery } from '@market/graphql/queries'
import { useMarketQuery } from './useMarketQuery'
import { DateTime } from 'luxon'

export type AvailableLocation = SchoolLocation & {
  menus: AvailableMenu[]
  availabilityDates: DateTime[]
  fullAvailabilityDates: DateTime[]
  blockedDates: DateTime[]
  mealServiceDays: string[] // ('MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY')[]
}
export type AvailableLocations = {
  [x: string]: AvailableLocation
}

export const useAvailableMenus = (options?: QueryHookOptions) => {
  const queryResult = useMarketQuery<{ availableMenus: AvailableMenu[] }>(
    availableMenusQuery,
    {
      pollInterval: options?.pollInterval,
    }
  )

  const availableLocations: AvailableLocations = useMemo(() => {
    return queryResult.data?.availableMenus.reduce<AvailableLocations>((locations, menu) => {
      locations[menu.location.id] ||= {
        ...menu.location,
        menus: [],
        availabilityDates: [],
        fullAvailabilityDates: [],
        blockedDates: [],
        mealServiceDays: [],
      }

      locations[menu.location.id].menus.push(menu)

      locations[menu.location.id].availabilityDates = [
        ...locations[menu.location.id].availabilityDates,
        ...menu.availabilityDates.map((date) => DateTime.fromISO(date).setZone(menu.location.timeZone).startOf('day'))
      ]
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a <= b ? -1 : 1)

      locations[menu.location.id].fullAvailabilityDates = [
        ...locations[menu.location.id].fullAvailabilityDates,
        ...menu.fullAvailabilityDates.map((date) => DateTime.fromISO(date).setZone(menu.location.timeZone).startOf('day'))
      ]
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a <= b ? -1 : 1)

      locations[menu.location.id].blockedDates = [
        ...locations[menu.location.id].blockedDates,
        ...menu.blockedDates.map((date) => DateTime.fromISO(date).setZone(menu.location.timeZone).startOf('day'))
      ]
      .filter((v, i, a) => a.indexOf(v) === i)
      .sort((a, b) => a <= b ? -1 : 1)

      locations[menu.location.id].mealServiceDays = [
        ...locations[menu.location.id].mealServiceDays,
        ...menu.mealServiceDays,
      ].filter((v, i, a) => a.indexOf(v) === i)

      return locations
    }, {})
  }, [queryResult.data?.availableMenus])

  const payload = useMemo(() => ({
    ...queryResult,
    data: {
      availableMenus: [],
      availableLocations,
      ...queryResult.data,
    }
  }), [queryResult, availableLocations])

  return payload
}

export default useAvailableMenus
