import React from 'react'
import { Page, Button, Box, Text } from 'components'
import { useStripeAddPaymentModal } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { DelinquentForm } from '@market/components'
import { AddPaymentMethodModal, PaymentMethodList } from '@market/compositions/StripePayments'

export const Settings: React.FC = () => {
  const { account: { id: accountId, delinquentFor }, paymentMethods, setupIntent } = useAccountContext()
  const modal = useStripeAddPaymentModal(setupIntent?.clientSecret)
  const { openModal } = modal

  return <Page>
    <Box>
      <Text variant="h4" mb={4}>Manage payment methods</Text>

      <PaymentMethodList paymentMethods={paymentMethods} />

      <Box mt={2}>
        <Button onClick={openModal} fullWidth={false} color="secondary">Add payment method</Button>
      </Box>
    </Box>

    <AddPaymentMethodModal {...modal} />

    { !!accountId && delinquentFor > 0 && <Box sx={(theme) => ({ mt: 4, pt: 4, borderTop: `1px solid ${theme.palette.divider}`})}>
      <Text variant="h4" mb={4}>Account balance</Text>

      <Text>Your account is past-due. Please resolve the outstanding balance in order to avoid having your account suspended.</Text>

      <DelinquentForm openModal={openModal} />
    </Box> }
  </Page>
}

export default Settings
