import React from 'react'
import { EmptyState, Page } from 'components'
import { Spinner } from 'components/Loading'
import { Menu as MenuIcon } from 'icons'
import { Menus as MenusPage } from '@market/compositions/Menus'
import { useAvailableMenus } from '@market/hooks/useAvailableMenus'

export const Menus: React.FC = () => {
  const { data, loading } = useAvailableMenus()

  return <Page fullWidth unpadded>
    { loading && data.availableMenus.length === 0 && <Spinner message="Loading Menus..." /> }
    { !loading && data.availableMenus.length > 0 && <MenusPage availableMenus={data.availableMenus} availableLocations={data.availableLocations} /> }
    { !loading && data.availableMenus.length === 0 && <EmptyState icon={MenuIcon} message="No items to display." /> }
  </Page>
}

export default Menus
