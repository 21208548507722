import React, { useCallback, useMemo, useState } from 'react'
import { BoxProps, CircularProgress, styled } from '@mui/material'
import { DateTime } from 'luxon'
import {
  Box,
  Button,
  Chip,
  Text,
  Select,
  Currency,
} from 'components'
import { Add as AddIcon, Pencil as PencilIcon } from 'icons'
import { useDrawerSettings, useViewport } from 'hooks'
import { AccountUser, Cart, CartItem, MenuProduct, Roster, Term } from '@market/graphql/schema/graphql'
import { useAddCartItems, useUpdateCartItem, useRemoveCartItem, useUpdateAccountUser } from '@market/hooks'
import { useAccountContext } from '@market/hooks/useCurrentAccount'
import { ProductModal } from '../ProductModal'
import { QuantityButtonGroup } from '../QuantityButtonGroup'

type UserWithCart = {
  accountUser: AccountUser
  cart?: Cart
  cartItem?: CartItem
}

type RosterSelectorProps = BoxProps & {
  accountUser: AccountUser
  term: Term
  roster: Roster
}

export const RosterSelector: React.FC<RosterSelectorProps> = ({ accountUser, term, roster, ...boxProps }) => {
  const availableRosters = useMemo(() => (term?.rosters || []).filter(roster => roster.isVisible && !roster.isArchived), [term])
  const rostersAvailable = useMemo(() => availableRosters.length > 0, [availableRosters])
  const rosterOptions = useMemo(() => availableRosters.map((roster) => ({ value: roster.id, label: roster.displayName })), [availableRosters])
  const [ showSelector, setShowSelector ] = useState<boolean>(!!roster)
  const [ updateAccountUser, { loading } ] = useUpdateAccountUser()

  const accountUserPayload = useMemo(() => ({
    firstName: accountUser.user?.firstName,
    lastName: accountUser.user?.lastName,
    preferredName: accountUser.user?.preferredName,
    phoneNumber: accountUser.user?.phoneNumber,
    role: accountUser.roles?.filter((role) => ['GUARDIAN', 'STUDENT', 'STAFF'].includes(role))[0],
    locationId: accountUser.user?.roster?.term?.locationId || accountUser.location?.id,
    rosterId: accountUser.user?.roster?.id,
    posEnabled: accountUser.settings?.posEnabled || false,
  }), [accountUser])

  const onChangeRoster = useCallback((rosterId: string) => {
    updateAccountUser({ variables: { id: accountUser.id, accountUser: { ...accountUserPayload, rosterId } } })
  }, [accountUser, accountUserPayload, updateAccountUser])

  if (!rostersAvailable) return null

  return <Box {...boxProps}>
    { !!term && term.rosters.length > 0 && <Box>
      { !showSelector && <Chip color="error" label={<Text fontSize="small">Missing info <PencilIcon fontSize="inherit" sx={{ ml: 1 }} /></Text>} onClick={() => setShowSelector(true)} /> }

      { showSelector && <Select
        disabled={loading}
        size="small"
        variant="filled"
        id="rosterId"
        name="rosterId"
        label={term.rosters[0]?.groupName}
        placeholder={term.rosters[0]?.groupName}
        onChange={(evt) => {
          onChangeRoster(evt.target.value.toString())
        }}
        value={roster?.id || ''}
        options={rosterOptions}
        sx={{ minWidth: '120px' }}
      /> }
    </Box> }
  </Box>
}

const StyledRosterSelector = styled(RosterSelector)(({ roster }) => ({
  '& .MuiInputLabel-sizeSmall': {
    display: !!roster ? 'none' : 'block',
    top: '-8px',
    '&.Mui-focused': {
      display: 'none',
    },
  },
  '& .MuiInputBase-inputSizeSmall': {
    paddingTop: '5px',
  },
}))

export const AddUserProduct: React.FC<UserWithCart & { menuProduct: MenuProduct, date: string }> = ({ menuProduct, date, accountUser, cartItem }) => {
  const [addCartItems, { loading: addLoading }] = useAddCartItems()
  const [updateCartItem, { loading: updateLoading }] = useUpdateCartItem()
  const [removeCartItem, { loading: removeLoading }] = useRemoveCartItem()
  const loading = useMemo(() => addLoading || updateLoading || removeLoading, [addLoading, updateLoading, removeLoading])

  const isoDate = useMemo(() => DateTime.fromISO(date), [date])
  const term = useMemo(() => accountUser.location.terms.find((term) => DateTime.fromISO(term.startDate) <= isoDate && DateTime.fromISO(term.endDate) >= isoDate), [isoDate, accountUser])
  const roster = useMemo(() => accountUser.user.rosters.find((roster) => roster.term.id === term?.id), [term, accountUser])
  // const disabled = useMemo(() => loading || (!!term && term.rosters.length > 0 && !roster), [loading, term, roster])

  const handleAddCartItem = useCallback((userId: string, date: string) => {
    addCartItems({
      variables: {
        items: [{
          date,
          userId,
          quantity: 1,
          menuId: menuProduct.menu.id,
          menuProductId: menuProduct.id,
          productId: menuProduct.product.id,
          variantId: menuProduct.variant.id,
          price: menuProduct.product.price,
        }]
      },
    })
  }, [addCartItems, menuProduct])

  const handleIncreaseQuantity = useCallback(() => {
    updateCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id, quantity: cartItem.quantity + 1 } })
  }, [updateCartItem, cartItem])

  const handleDecreaseQuantity = useCallback(() => {
    if (cartItem.quantity === 1) {
      removeCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id } })
    } else {
      updateCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id, quantity: cartItem.quantity - 1 } })
    }
  }, [updateCartItem, removeCartItem, cartItem])

  const handleChangeQuantity = useCallback((quantity: number) => {
    if (quantity === 0) {
      removeCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id } })
    } else {
      updateCartItem({ variables: { cartId: cartItem.cartId, itemId: cartItem.id, quantity: quantity } })
    }
  }, [updateCartItem, removeCartItem, cartItem])


  return <Box display="flex" alignItems="center" justifyContent="flex-end" gap={2} py={1} my={1} sx={(theme) => ({
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.divider,
  })}>
    <Text variant="h6" pl={2}>{ accountUser.user.preferredName || accountUser.user.firstName }</Text>

    <StyledRosterSelector key={date} accountUser={accountUser} term={term} roster={roster} py={2} />

    <Box py={2} ml="auto" display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
      { !!cartItem && <QuantityButtonGroup
        onIncrease={handleIncreaseQuantity}
        onDecrease={handleDecreaseQuantity}
        onChange={handleChangeQuantity}
        disabled={loading}
        value={cartItem.quantity.toString()}
      /> }

      { !cartItem && <Text>{ DateTime.fromISO(date).toFormat('ccc, LLL d') }</Text> }

      { !cartItem && <Button
        size="small"
        fullWidth={false}
        disabled={loading}
        onClick={() => handleAddCartItem(accountUser.user.id, date)}
        sx={{ borderRadius: '50%', minWidth: '40px', width: '40px', height: '40px', padding: 0, fontSize: 'inherit' }}
        variant="contained"
        color="inherit"
      >
        <AddIcon fontSize="inherit" />
      </Button> }
    </Box>

    <Text minWidth={!!cartItem || loading ? '50px' : undefined} textAlign="right">
      { !loading && !!cartItem && <Currency value={cartItem.subtotalCents / 100} /> }
      { loading && <CircularProgress size={20} /> }
    </Text>
  </Box>
}

export const AddProductModal: React.FC<{
  menuProduct: MenuProduct,
  setDate: (date: string) => void,
  onClose: () => void,
  date: string,
  open?: boolean
}> = ({ menuProduct, date, open, setDate, onClose }) => {
  const { isMedium } = useViewport()
  const [{}, { setContentDrawer }] = useDrawerSettings()
  const { accountUsers, carts } = useAccountContext()

  const usersWithCarts: UserWithCart[] = useMemo(() => {
    return accountUsers?.reduce((memo, acctUser) => {
      if ((acctUser.location?.id || acctUser.user.roster?.term?.location?.id) === menuProduct?.menu?.location?.id) {
        const userCart = carts.find((cart) => cart.user.id === acctUser.user.id)
        const userWithCart = {
          accountUser: acctUser,
          cart: userCart,
          cartItem: userCart?.cartItems?.find((item) => item.menuProduct.id === menuProduct?.id && item.date === date),
        }
  
        memo.push(userWithCart)
      }

      return memo
    }, []) || []
  }, [accountUsers, carts, menuProduct, date])

  const itemInCarts = useMemo(() => usersWithCarts.filter((uc) => !!uc.cartItem).length > 0, [usersWithCarts])

  const availableOnDate = useMemo(() => menuProduct?.availabilityDates?.length > 0 && menuProduct?.availabilityDates?.includes(date), [date, menuProduct?.availabilityDates])

  if (!menuProduct) return null

  return <ProductModal
    open={!!open}
    fullScreen={!isMedium}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
    product={menuProduct.product}
    actions={
      itemInCarts && <Box width="100%">
        <Button
          disabled={!itemInCarts}
          onClick={() => {
            setContentDrawer(true, 'carts')
            onClose()
          }}
        >View in cart</Button>
      </Box>
    }
  >
    { !availableOnDate && <Text>This is a preview of an upcoming menu, and this item is not currently available to order on {DateTime.fromISO(date).toFormat('cccc, LLLL d')}. Future menus may be subject to change.</Text>}

    { availableOnDate && <>
      { menuProduct?.availabilityDates?.length > 1 && <Box pb={2}>
        <Text variant="h6" pb={1}>Select a date</Text>

        <Select
          options={menuProduct.availabilityDates.map((dateOpt) => ({
            label: DateTime.fromISO(dateOpt).toFormat('cccc, LLLL d'),
            value: dateOpt,
          }))}
          value={date}
          onChange={(evt) => setDate(evt.target.value.toString())}
        />
      </Box> }

      { menuProduct?.availabilityDates?.length === 1 && <Box>
        <Text variant="h6" pb={2}>{ DateTime.fromISO(date).toFormat('cccc, LLLL d') }</Text>
      </Box> }

      { usersWithCarts.map((userWithCart) => <AddUserProduct
        key={userWithCart.accountUser.id}
        {...userWithCart}
        date={date}
        menuProduct={menuProduct}
      />) }

      <Box sx={(theme) => ({
        display: 'flex',
        pt: 2,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.divider,
      })}>
        <Text>Subtotal</Text>
        <Text ml="auto"><Currency value={usersWithCarts.reduce((memo, userWithCart) => memo + (userWithCart.cartItem?.subtotalCents || 0), 0) / 100} /></Text>
      </Box>
    </> }
  </ProductModal>
}

export default AddProductModal
